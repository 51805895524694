import React from 'react'
import './styles.scss'
import InvalidIcon from '../../assets/images/invalid-icon.svg'

const ExpiredProject = () => {
  return (
    <div data-container="invalid">
      <div className="flex justify-center">
        <img src={InvalidIcon} alt="" />
      </div>
      <div className="flex justify-center">
        The project link is invalid
        <br />
        or has expired.
      </div>
      <div className="flex justify-center">Make sure you have the correct link.</div>
    </div>
  )
}

export default ExpiredProject
