import { fetchApi } from '../../services/fetchApi'
import WavesurferPlayer from '@wavesurfer/react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import './styles.scss'
import PauseIcon from '../../assets/images/pause-icon.svg'
import PlayIcon from '../../assets/images/play-icon.svg'
import VolumeIcon from '../../assets/images/volume-icon.svg'
import Loader from '../../components/loader/loader'
import { SetStateAction, useState, useEffect } from 'react'

const AudioPlayer = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, isLoading, error } = useQuery(`player/audio/${id}`, () => fetchApi(`player/audio/${id}`), {
    enabled: !!id,
    retry: false,
    onError: () => navigate(`/expired/${id}`),
  })
  const [wavesurfer, setWavesurfer] = useState<any>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [spaceDown, setSpaceDown] = useState(true)
  const [volume, setVolume] = useState(1)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const currentDate = new Date()
  const expireDate = new Date(data?.expireDate)
  const differenceInTime = expireDate.getTime() - currentDate.getTime()
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))

  let expirationMessage
  if (differenceInDays > 0) {
    expirationMessage = `Available for ${differenceInDays} more days`
  } else if (differenceInDays === 0) {
    expirationMessage = `Expires today at ${expireDate.toLocaleTimeString()}`
  } else {
    expirationMessage = 'Expired'
  }
  useEffect(() => {
    if (wavesurfer && typeof wavesurfer.playPause === 'function') {
      wavesurfer.playPause()
      setIsPlaying(!spaceDown)
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        setSpaceDown((prevSpaceDown) => !prevSpaceDown)
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [wavesurfer, spaceDown])

  if (isLoading || !data || error) {
    return <Loader />
  }
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const onReady = (ws: SetStateAction<any>) => {
    setWavesurfer(ws)
    setIsPlaying(false)
    ws.setVolume(volume)
    setDuration(ws.getDuration())
    ws.on('audioprocess', () => {
      setCurrentTime(ws.getCurrentTime())
    })
  }

  const onPlayPause = () => {
    if (wavesurfer && typeof wavesurfer.playPause === 'function') {
      wavesurfer.playPause()
      setIsPlaying(!isPlaying)
    }
  }

  const onVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value)
    setVolume(newVolume)
    wavesurfer.setVolume(newVolume)
  }
  const handleWaveClick = (event: { currentTarget: any; clientX: number }) => {
    const waveElement = event.currentTarget
    const boundingRect = waveElement.getBoundingClientRect()
    const clickX = event.clientX - boundingRect.left
    const progress = clickX / waveElement.clientWidth
    const newTime = progress * duration
    setCurrentTime(newTime)
    if (wavesurfer) {
      wavesurfer.seekTo(progress)
    }
  }
  return (
    <div data-container="player">
      {duration === 0 && <Loader />}
      <div className="wave-surfer-body" onClick={handleWaveClick}>
        <WavesurferPlayer
          url={data.url}
          height={144}
          barWidth={2}
          barHeight={0.5}
          barRadius={6}
          barGap={5}
          hideScrollbar={true}
          fillParent={true}
          autoCenter={false}
          autoScroll={false}
          waveColor="#fff"
          progressColor="#666666"
          cursorColor="white"
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
      <div className="audio-title">
        <div>
          <div>
            <p onClick={onPlayPause} style={{ backgroundImage: `url(${isPlaying ? PauseIcon : PlayIcon})` }}></p>
          </div>
          <div>
            <h3>{data && data.name}</h3>
            <div>
              <p>
                <span>{formatTime(currentTime)}</span>/ {formatTime(duration)}
              </p>
              <p>- {expirationMessage}</p>
            </div>
          </div>
        </div>
        <div className="volume-bar">
          <p style={{ backgroundImage: `url(${VolumeIcon})` }}></p>
          <input name="Volume" type="range" min="0" max="1" step="0.01" value={volume} onChange={onVolumeChange} />
        </div>
      </div>
    </div>
  )
}

export default AudioPlayer
