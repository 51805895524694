const API_URL = process.env.REACT_APP_PLAYER_BACKEND_URL || 'http://localhost:8100/api'
export const API_HEADERS: Record<string, string> = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  RefreshToken: `${localStorage.getItem('refreshToken')}`,
}

interface RequestOptions<T> {
  body?: T | undefined | string
  headers?: HeadersInit
  method?: 'POST' | 'PUT' | 'DELETE'
}

type ApiError = {
  message: string
  response?: Response & { message: string }
}

export async function fetchApi<T>(url: string, options: RequestOptions<T> = {}): Promise<any> {
  const requestOptions: RequestOptions<T> = {
    ...options,
    headers: {
      ...API_HEADERS,
      ...options.headers,
    },
    body: options.body && JSON.stringify(options.body),
  }

  const res = await fetch(`${API_URL}/${url}`, requestOptions as RequestInit)

  if (!res.ok) {
    const error: ApiError = new Error(`An error occurred: ${res.status}`)
    error.response = await res.json()
    throw error
  }

  return await res.json()
}
