import { Route, Routes } from 'react-router-dom'
import './app.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import logo from './assets/images/logo.png'
import { AudioPlayer } from './modules'
import ExpiredProject from './modules/player/expiredProject'

const App = () => {
  return (
    <div className="container">
      <header>
        <a href="https://www.audigolabs.com">
          <img src={logo} alt="logo" />
        </a>
      </header>
      <section>
        <Routes>
          <Route path="/audio/:id" element={<AudioPlayer />} />
          <Route path="/expired/:id" element={<ExpiredProject />} />
        </Routes>
      </section>
    </div>
  )
}

export default App
