import React from 'react'
import './loader.scss'

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '1',
}

const Loader = () => {
  return <div style={styles} className="loader" aria-busy={true}></div>
}

export default Loader
